.loading {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading .loader {
  border: 3px solid #9d9d9d;
  border-radius: 50%;
  border-top: 3px solid rgb(45, 45, 45);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.logo-loading {
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1;
}
.logo-loading .loader {
  position: absolute;
  top: 47%;
  left: 47%;
}
.logo-loading .loader img {
  width: 40px;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
