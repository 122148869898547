.admin-sidebar {
  max-width: 280px;
  min-width: 280px;
  min-height: 100vh;
  background-color: #024d43;
  transition: margin 0.25s ease-out;
  z-index: 100;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .title {
    margin: 20px;
    font-weight: bold;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  .button {
    margin-top: 20px;
  }
}
