.App {
  .main-app-container {
    width: 100%;
    display: flex;
    .routes {
      height: 100%;
      display: flex;
    }
  }
}
